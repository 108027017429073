import Parse from "parse"
import React, { useEffect, useState } from "react"
import moment from "moment"
import { formatDate } from "../utils/helpers"
import { useSelector } from "react-redux"

const PublicPageAppointmentDetails = (props) => {
  const { selectedPatient, locationId } = props
  const slotAndDate = useSelector(
    (state) => state?.Location?.timeSloteAndLocation
  )
  const [recalLocation, setRecalLocation] = useState("")
  useEffect(() => {
    const getLocation = async () => {
      const response = new Parse.Query("LocationV1")
      const locations = await response.get(locationId)
      setRecalLocation(locations.toJSON())
    }
    if (locationId) {
      getLocation()
    }
  }, [])

  return (
    <>
      <p className="text-sm md:text-lg mt-1 md:mt-3 text-mobile-grey-600 mb-4">
        Appointment details
      </p>
      <table className="table-auto mb-3">
        <tbody>
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Patient name:</b>
            </td>
            <td className="p-2">
              {selectedPatient?.firstName}
              &nbsp;{selectedPatient?.lastName}
            </td>
          </tr>
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Location:</b>
            </td>
            <td className="p-2">
              {locationId
                ? recalLocation?.name
                : "Total Health Dental Care Rotunda"}
            </td>
          </tr>
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Location address:</b>
            </td>
            {locationId ? (
              <td className="p-2">
                {recalLocation?.address1},&nbsp;{recalLocation?.city}
                ,&nbsp;
                {recalLocation?.state},&nbsp;{recalLocation?.postalCode}
              </td>
            ) : (
              <td className="p-2">
                300 Frank H. Ogawa Plaza Suite 100, Oakland, CA, 94612-0000
              </td>
            )}
          </tr>
          {slotAndDate?.timeSlot ? (
            <>
              <tr>
                <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
                  <b>Date:</b>
                </td>
                <td className="p-2">
                  {moment(slotAndDate?.date).format("MMMM DD, YYYY")}
                </td>
              </tr>
              <tr>
                <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
                  <b>Time slot:</b>
                </td>
                <td className="p-2">
                  {formatDate(slotAndDate?.timeSlot?.start, "h:mm a", "HH:mm")}
                </td>
              </tr>
            </>
          ) : null}
        </tbody>
      </table>
    </>
  )
}

export default PublicPageAppointmentDetails
