import React, { useEffect } from "react"
import { toast } from "react-toastify"
import Button from "./Button"
import AppointmentDetails from "./AppointmentDetails"
import StickyContainer from "./StickyContainer"
import { useSelector } from "react-redux"

const SquareCard = (props) => {
  const {
    isSquareProduction,
    saveCard,
    handleCreateAppt,
    selectedPatientCard,
    patientId,
    isLoadingBtn,
    creditCardState
  } = props

  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )

  useEffect(async () => {
    if (selectedPatientCard?.length <= 0) {
      const appId = isSquareProduction
        ? process.env.REACT_APP_SQUARE_APPLICATION_ID
        : process.env.REACT_APP_SQUARE_SANDBOX_APPLICATION_ID
      const locationId = isSquareProduction
        ? selectedPatient?.preferredLocation?.squareLocationId
        : selectedPatient?.preferredLocation?.squareSandboxLocationId
      async function initializeCard(payments) {
        const card = await payments.card()
        await card.attach("#card-container")
        return card
      }
      async function tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize()
        if (tokenResult.status === "OK") {
          return tokenResult
        } else {
          let errorMessage = `Tokenization failed with status: ${tokenResult.status}`
          if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`
          }

          throw new Error(errorMessage)
        }
      }
      if (!window.Square) {
        throw new Error("Square.js failed to load properly")
      }
      let payments
      try {
        payments = window.Square.payments(appId, locationId)
      } catch (error) {
        console.log(error)
        return
      }
      let card
      try {
        card = await initializeCard(payments)
      } catch (error) {
        console.log(error)
        return
      }
      async function handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault()
        try {
          cardButton.disabled = true
          const token = await tokenize(paymentMethod)
          saveCard(token)
        } catch (error) {
          cardButton.disabled = false
          console.log(error)
        }
      }
      const cardButton = document.getElementById("card-button")
      cardButton.addEventListener("click", async function (event) {
        await handlePaymentMethodSubmission(event, card)
      })
    }
  }, [])

  if (patientId || window.location.pathname === "/chk-cc") {
    return (
      <form id="payment-form">
        <div id="card-container"></div>
        <StickyContainer>
          <Button loading={isLoadingBtn} id="card-button" title="Confirm" />
        </StickyContainer>
      </form>
    )
  } else {
    if (!creditCardState) {
      return (
        <>
          <AppointmentDetails />
          <StickyContainer>
            <Button
              loading={isLoadingBtn}
              onClick={handleCreateAppt}
              title="Confirm"
            />
          </StickyContainer>
        </>
      )
    } else {
      if (selectedPatientCard?.length <= 0) {
        return (
          <form id="payment-form">
            <div id="card-container"></div>
            <AppointmentDetails />
            <StickyContainer>
              <Button loading={isLoadingBtn} id="card-button" title="Confirm" />
            </StickyContainer>
          </form>
        )
      } else {
        return (
          <>
            <AppointmentDetails />
            <StickyContainer>
              <Button
                loading={isLoadingBtn}
                onClick={handleCreateAppt}
                title="Confirm"
              />
            </StickyContainer>
          </>
        )
      }
    }
  }
}

export default SquareCard
