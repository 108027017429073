import { useHistory, useLocation } from "react-router";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import PageTitle from "../../components/PageTitle";
import StickyContainer from "../../components/StickyContainer";
import InsuranceDetails from "../../components/InsuranceDetails";
import Parse from "parse"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AppointmentDetails from "../../components/AppointmentDetails";

export default function InsuranceConfirmation() {
  let history = useHistory();
  const [insuranceInfo, setInsuranceInfo] = useState()
  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )
  const fromPublicPage = history.location.state && history.location.state?.from === 'publicInsurancePage'
  const getInsuranceInfo = async () => {
    let patientId
    if (fromPublicPage) {
      patientId = history.location.state?.patientId
    } else {
      patientId = selectedPatient.objectId
    }
    try {
        const insuranceInfo = await new Parse.Query('Insurance').equalTo('patient', patientId).descending('createdAt').first()
        setInsuranceInfo(insuranceInfo?.toJSON())
    } catch (error) {
        console.log(error)
    }
  }
  useEffect(() => {
    if (selectedPatient || fromPublicPage) {
      getInsuranceInfo()
    }
  }, [])

  return (
    <PageContainer
      step={7}
      leftContent={
        <>
          <PageTitle title={ !fromPublicPage ? "Appointment & Insurance" : "Insurance Details"} className="mb-0">
            { !fromPublicPage ? 'Here are your Appointment & Insurance Information.' : 'Here are your Insurance Information.' }
          </PageTitle>
          {
            !fromPublicPage && 
            <div
              id="form-container"
              className="bg-mobile-grey-50 mb-4 p-4 pb-2 rounded-lg"
            >
                <AppointmentDetails />
            </div>
          }
          <div
            id="form-container"
            className="bg-mobile-grey-50 mb-4 p-4 pb-2 rounded-lg"
          >
            <InsuranceDetails insuranceInfo={insuranceInfo} />
          </div>
          <StickyContainer>
                <Button
                    title={!fromPublicPage && selectedPatient?.hasCompletedAppointment ? 'Create Another Appointment' : 'Home'}
                    onClick={() => history.push("/")}
                />
            </StickyContainer> 
        </>
      }
    />
  );
}
