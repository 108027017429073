import { config } from "../../utils/config"
import { LandingPageHeader } from "./header"
import { RiStarFill } from "react-icons/ri"
import { FcGoogle } from "react-icons/fc"
import { SiYelp } from "react-icons/si"
import mapboxgl from "mapbox-gl"
import { parseConfig } from "../../utils/ParseConfig";
import Parse from "parse"
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"
import { useEffect, useState } from "react"
import Map from "../../components/Map"
import Review from "."
import { removeTotalHeathCareName, formatPhoneNumber } from "../../utils/helpers"

mapboxgl.workerClass = MapboxWorker

export default function ReviewLandingPage() {

  const search = window.location.search
  const params = new URLSearchParams(search)
  const appointmentId = params.get("id")
  const [isOpen, setIsOpen] = useState(false)
  const [reviewExpiryDate, setReviewExpiryDate] = useState(null)
  const [patientId, setPatientId] = useState(null)
  const [location, setLocation] = useState(null)
  const [locationReviewLink, setLocationReviewLink] = useState({googleLink: null, yelpLink: null })

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const selectedLocation = {
    objectId: location?.objectId ?? process.env.REACT_APP_ROTUNDA_LOCATION_ID,
  }

  const getAppointment = async () => {

    parseConfig()
    const appointment = await new Parse.Query("AppointmentV1")
      .equalTo('objectId', appointmentId)
      .include(['patient', 'location'])
      .first()

    const appointmentJSON = appointment?.toJSON()

    const updateReviewExpiry = appointmentJSON?.patient?.updateReviewExpiry
    const patient_id = appointmentJSON?.patient.objectId
    setReviewExpiryDate(new Date(updateReviewExpiry?.iso))
    setPatientId(patient_id)
    setLocation(appointmentJSON?.location)
  }
  useEffect(() => {
    if (appointmentId) {
        getAppointment()
    }
  }, [])
  useEffect(() => {
    if (reviewExpiryDate && (new Date() < reviewExpiryDate)) {
        openModal()
    }
  }, [reviewExpiryDate])
  useEffect(() => {
    if (location) {
      getReviewLinkForLocation(location.objectId)
    }
  }, [location])
  
  const getReviewLinkForLocation = async (locationId) => {
    const reviewLinkQuery = await new Parse.Query('ReviewPlatformAddress').equalTo('location', locationId).first()
    if (reviewLinkQuery) {
      const reviewLinkJSON = reviewLinkQuery.toJSON()
      setLocationReviewLink({googleLink: reviewLinkJSON.googleLink, yelpLink: reviewLinkJSON.yelpLink })
    }
  }

  return (
    <>
      <LandingPageHeader />
      <div className="pt-20 ">
        <div
          className="relative"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(000, 000, 000, 0.52), rgba(000, 000, 000, 0.73)), url(${config.app.docsBanner})`,
            height: "550px",
          }}
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-bold text-5xl text-white text-center">
            WE <br /> LOVE FEEDBACK!
          </div>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="px-5 py-16">
            <div className="text-center text-gray-700">
              <h1 className="font-bold text-5xl">How was your experience?</h1>
              <p className="text-xl my-4 text-center max-w-4xl mx-auto">
                We are committed to a 5-star patient experience. If you’re happy
                with your visit today, let us know by posting on Google and
                Yelp.
              </p>
            </div>
            <div className="w-full flex justify-center py-5">
                <a target="_blank" href={locationReviewLink.googleLink} className="w-full p-5 rounded-lg shadow-xl lg:max-w-xs mx-4 cursor-pointer hover:shadow-2xl border border-1  border-gray-100">
                    <div>
                        <div className="flex justify-center">
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        </div>
                        <div className="my-2">
                        <FcGoogle size={70} className="fill-yellow-800 mx-auto" />
                        </div>
                        <h4 className="text-xl text-center font-semibold text-gray-700 ">
                        Review us on Google
                        </h4>
                    </div>
                </a>
                <a target="_blank" href={locationReviewLink.yelpLink} className="w-full p-5 rounded-lg shadow-xl lg:max-w-xs mx-4 cursor-pointer hover:shadow-2xl border border-1 border-gray-100">
                    <div>
                        <div className="flex justify-center">
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        <RiStarFill
                            size={28}
                            fill="#ffe234"
                            className="mr-1 fill-yellow-800"
                        />
                        </div>
                        <div className="my-3">
                        <SiYelp
                            size={63}
                            fill="#c41200"
                            className="fill-yellow-800 mx-auto"
                        />
                        </div>
                        <h4 className="text-xl text-center font-semibold text-gray-700 ">
                        Review us on Yelp
                        </h4>
                    </div>
                </a>
            </div>
          </div>
        </div>
          <hr />
        <div className="max-w-6xl mx-auto w-full flex justify-center px-5 py-16 text-gray-700">
          <div className="mr-4 max-w-sm">
            {
                location && <>
                    <p className="mb-4 text-xl font-medium ">{ removeTotalHeathCareName(location?.name)}</p>
                    <p className="mb-2 underline w-64">
                    {`${location?.address1} ${location?.city}, ${location?.state} ${location?.postalCode}` }
                    </p>
                    <p className="mb-2 underline"> { formatPhoneNumber(location?.phone) } </p>
                </> 
            }
          </div>
          <div className="h-96 w-96">
            <Map
              locations={[selectedLocation]}
              initialZoom={15}
              mapClass="md:w-full"
            />
          </div>
        </div>
        <div
          className="px-5 py-16 bg-center bg-cover brightness-50 bg-gray-500"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(000, 000, 000, 0.52), rgba(000, 000, 000, 0.73)), url(${config.app.docsBanner})`,
          }}
        >
          <div className="max-w-6xl mx-auto text-center">
            <p className="text-3xl my-4 font-medium max-w-4xl mx-auto text-center text-white">
              If we fell short of your expectations, email us and we’ll do
              everything possible to turn your experience around!
            </p>

            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
              Email Us
            </button>
          </div>
        </div>

        <footer className="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2022{" "}
            <a href="https://total.com/" className="hover:underline">
              Total Health Dental Care™
            </a>
            . All Rights Reserved.
          </span>
          {/* <ul class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
                <li>
                    <a href="#" class="mr-4 hover:underline md:mr-6 ">About</a>
                </li>
                <li>
                    <a href="#" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
                </li>
                <li>
                    <a href="#" class="mr-4 hover:underline md:mr-6">Licensing</a>
                </li>
                <li>
                    <a href="#" class="hover:underline">Contact</a>
                </li>
            </ul> */}
        </footer>
      </div>

        <Review isOpen={isOpen} closeModal={closeModal} patientId={patientId} />
    </>
  )
}
