import Parse from "parse"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import PageContainer from "../../components/PageContainer"
import PageTitle from "../../components/PageTitle"
import Loader from "../../components/Loader"
import { appointmentCreatedStatus, rotundaSlotsFilter } from "../../Store/Location/actions"
import { parseConfig } from "../../utils/ParseConfig"
import axios from "axios"
import { getSelectedPatientCard } from "../../Store/Auth/actions"
import SquareCard from "../../components/SquareCard"

axios.defaults.withCredentials = false

export default function CardDetails() {
  let history = useHistory()
  const dispatch = useDispatch()

  const search = window.location.search
  const params = new URLSearchParams(search)
  const patientId = params.get("id")
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [creditCardState, setCreditCardState] = useState(true)
  const [isSquareProduction, setIsSquareProduction] = useState(
    process.env.REACT_APP_SQAURE_PRODUCTION === "TRUE"
  )
  const rotundaSlotsFillter = useSelector(
    (state) => state?.Location?.rotundaSlotsFilter
  )
  const mainPatient = useSelector((state) => state?.AuthRed?.mainPatient)
  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )
  const note = useSelector((state) => state?.AuthRed?.appointmentNote)
  const formType = useSelector((state) => state?.AuthRed?.formType)
  const appointmentCreatedState = useSelector(
    (state) => state?.Location.appointmentCreated
  )
  const selectedPatientCard = useSelector(
    (state) => state?.AuthRed?.selectedPatientCard
  )
  const slotState = useSelector((state) => state?.Location.timeSloteAndLocation)

  useEffect(() => {
    const fetchConfigCreditCard = async () => {
      const config = await Parse.Config.get()
      const creditCard = config.get("showCreditCard")
      setCreditCardState(creditCard)
    }
    fetchConfigCreditCard()
  }, [mainPatient])

  useEffect(() => {
    if (mainPatient) {
      dispatch(getSelectedPatientCard({ mainPatient }))
    }
  }, [mainPatient])

  useEffect(() => {
    if (window.location.pathname === "/chk-cc") {
      dispatch(appointmentCreatedStatus(false))
    } else {
      if (mainPatient) {
        if (appointmentCreatedState) {
          history.push("/patients")
        }
      }
    }
  }, [mainPatient])

  parseConfig()

  const handleCreateAppt = async () => {
    setIsLoadingBtn(true)
    try {
      window.gtag("event", "conversion", {
        send_to: process.env.REACT_APP_GTAG_SEND_TO,
      })
      window.fbq("track", "CompleteRegistration")
      await Parse.Cloud.run("bookingAppointmentCreate", {
        patientId: selectedPatient?.objectId,
        locationId: rotundaSlotsFillter
          ? process.env.REACT_APP_ROTUNDA_LOCATION_ID
          : slotState?.location?.objectId,
        date: moment(slotState?.date).format("YYYY-MM-DD"),
        start: slotState?.timeSlot?.start,
        generatedBy: "Web App",
        note: note,
      })
      // Log Success
      const { preferredLocation } = selectedPatient
      let phone = selectedPatient.phones?.length
        ? selectedPatient.phones[0]?.number
        : ""
      if (!phone) {
        phone = mainPatient.phones?.length ? mainPatient.phones[0]?.number : ""
      }
      let sheetLocation = selectedPatient.hasCompletedAppointment
        ? preferredLocation.name
        : preferredLocation.welcomeCenterLocation.name
      if (
        preferredLocation.objectId ===
        preferredLocation.welcomeCenterLocation.objectId
      ) {
        sheetLocation = preferredLocation.name
      }
      history.push("/confirmation")
      dispatch(appointmentCreatedStatus(true))
      setIsLoadingBtn(false)
    } catch (error) {
      setIsLoadingBtn(false)
      toast.error(JSON.stringify(error.message))
    }
  }

  const handleSaveCard = async (cardData) => {
    setIsLoadingBtn(true)
    try {
      if (patientId) {
        try {
          await Parse.Cloud.run("updatePatientCreditCard", {
            patientId: patientId,
            nonce: cardData?.token,
            enabled: true,
            expMonth: cardData?.details.card.expMonth,
            expYear: cardData?.details.card.expYear,
            last4: cardData?.details.card.last4,
            checkCreditCardExpiry: true,
          })
          toast.success("Card details update successfully")
          setIsLoadingBtn(false)
          history.push("/")
        } catch (error) {
          setIsLoadingBtn(false)
          toast.error(JSON.stringify(error.message))
        }
      } else {
        await Parse.Cloud.run("bookingPatientCardCreate", {
          patientId: mainPatient?.objectId,
          nonce: cardData?.token,
          cardholderName:
            selectedPatient?.firstName + " " + selectedPatient?.lastName,
          enabled: true,
          expMonth: cardData?.details.card.expMonth,
          expYear: cardData?.details.card.expYear,
          last4: cardData?.details.card.last4,
        })
        await handleCreateAppt()
      }
    } catch (error) {
      toast.error(JSON.stringify(error.message))
      setIsLoadingBtn(false)
    }
  }

  return (
    <PageContainer
      step={patientId ? 7 : 5}
      leftContent={
        <>
          {patientId ? (
            <PageTitle title={"Enter card details"}>
              We require a credit card to book your visit. Cancellation is free
              up to 72 hours before your visit. Canceling within 72 hours incurs
              a $50 charge.
            </PageTitle>
          ) : (
            <>
              {!creditCardState ? (
                <PageTitle title={"Please Confirm Booking"} />
              ) : (
                <>
                  {selectedPatientCard?.length > 0 ? (
                    <PageTitle title={"Please Confirm Booking"} />
                  ) : (
                    <PageTitle title={"Enter card details"}>
                      We require a credit card to book your visit. Cancellation
                      is free up to 72 hours before your visit. Canceling within
                      72 hours incurs a $50 charge.
                    </PageTitle>
                  )}
                </>
              )}
            </>
          )}
          {isLoadingBtn ? (
            <Loader />
          ) : (
            <SquareCard
              isSquareProduction={isSquareProduction}
              saveCard={handleSaveCard}
              selectedPatientCard={selectedPatientCard}
              handleCreateAppt={handleCreateAppt}
              patientId={patientId}
              isLoadingBtn={isLoadingBtn}
              creditCardState={creditCardState}
            />
          )}
        </>
      }
    />
  )
}
