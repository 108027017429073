import React from "react";

import { config } from "../utils/config";
import { formatDate } from "../utils/helpers";

const SlotButton = ({ onClick, value, isSelected = false }) => {
  return (
    <div
      className={`w-full px-3 py-2 border-0 text-sm rounded-lg shadow-sm ${
        isSelected
          ? "bg-black text-white font-semibold"
          : "bg-gray-100 text-black font-medium"
      } transition ease-in duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        isSelected ? config.app.focusRingColor : "focus:ring-gray-100 "
      } focus:shadow-lg hover:shadow-lg text-center cursor-pointer`}
      onClick={onClick}
    >
      <span className="whitespace-nowrap">
        {formatDate(value.start, 'h:mm a', 'HH:mm')}
      </span>
    </div>
  );
};

export default SlotButton;
