import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { config } from "../../utils/config";

const TOTAL_PROGRESS = 100
const TOTAL_STEPS = 7
export const LandingPageHeader = ({ step = 1 }) => {

    let progress = (TOTAL_PROGRESS / TOTAL_STEPS) * step
    if (progress > 100) progress = 100
    if (progress < 0) progress = 0
  
    return (
    <header className="relative z-10">
      <div className="absolute w-full bg-white">
        <nav className="relative flex items-center p-4 border-b-2 border-mobile-grey-400 md:p-5">
            <img
                className=" h-12 md:h-14 ml-5"
                src={config.app.headerIconSrc}
                alt="logo"
            />
            <div className="absolute right-10">
                <a href="https://schedule.totalhealthdentalcare.com/" target="_blank" className="bg-mobile-green-50 hover:shadow-lg text-white font-bold py-3 px-4 rounded-full">
                    Book Now
                </a>
            </div>
          <div
            className={`absolute left-0 -bottom-1 h-2 ${config.app.backgroundColor}`}
            style={{ width: `${progress}%` }}
          ></div>
        </nav>
      </div>
    </header>
    );
}