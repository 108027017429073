import { useHistory } from "react-router"
import Button from "../../components/Button"
import PageContainer from "../../components/PageContainer"
import PageTitle from "../../components/PageTitle"
import StickyContainer from "../../components/StickyContainer"
import AppointmentDetails from "../../components/AppointmentDetails"
import { useSelector } from "react-redux"

export default function Confirmation() {
  let history = useHistory()

  const hasCompletedAppointment = useSelector(
    (state) => state?.AuthRed?.selectedPatient?.hasCompletedAppointment
  )

  return (
    <PageContainer
      step={7}
      leftContent={
        <>
          <PageTitle title="Appointment Booked">
            Your appointment has been booked.
          </PageTitle>
          <div
            id="form-container"
            className="bg-mobile-grey-50 my-4 p-4 pb-2 rounded-lg"
          >
            <AppointmentDetails />
          </div>
          <StickyContainer>
            {!hasCompletedAppointment ? (
              <Button
                title="Now let’s get your Insurance Information"
                onClick={() => history.push("/insurance")}
              />
            ) : (
              <Button
                title="Create Another Appointment"
                onClick={() => history.push("/")}
              />
            )}
          </StickyContainer>
        </>
      }
    />
  )
}
