import * as types from "./actionTypes"

export const getLocation = (data) => {
  return {
    type: types.GET_LOCATION,
    payload: data,
  }
}
export const getLocationSuccess = (data) => {
  return {
    type: types.GET_LOCATIONS_SUCCESS,
    payload: data,
  }
}
export const selectedLocationSuccess = (data) => {
  return {
    type: types.SELECTED_LOCATION_SUCCESS,
    payload: data,
  }
}
export const timeSlotAndLocationSuccess = (data) => {
  return {
    type: types.TIME_SLOT_AND_LOCATION,
    payload: data,
  }
}
export const appointmentCreatedStatus = (data) => {
  return {
    type: types.APPOINTMENT_CREATED_STATUS,
    payload: data,
  }
}
export const insuranceCreatedStatus = (data) => {
  return {
    type: types.INSURANCE_CREATED_STATUS,
    payload: data,
  }
}

export const rotundaSlotsFilter = (data) => {
  return {
    type: types.ROTUNDA_SLOTS_FILTER,
    payload: data,
  }
}
