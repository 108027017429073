import Parse from "parse"
import { useEffect, useMemo, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { formatDate, notify } from "../../utils/helpers"
import Button from "../../components/Button"
import PageContainer from "../../components/PageContainer"
import SlotButton from "../../components/SlotButton"
import PageTitle from "../../components/PageTitle"
import StickyContainer from "../../components/StickyContainer"
import { parseConfig } from "../../utils/ParseConfig"
import Loader from "../../components/Loader"
import "react-nice-dates/build/style.css"
import Map from "../../components/Map"
import { getSelectedPatient } from "../../Store/Auth/actions"
import PublicPageAppointmentDetails from "../../components/PublicPageAppointmentDetails"
import { toast } from "react-toastify"
import "../../components/calendar-datepicker/DatePicker.css"
import { Calendar } from "../../components/calendar-datepicker"
import { timeSlotAndLocationSuccess } from "../../Store/Location/actions"
import { useLocation } from "react-router-dom/cjs/react-router-dom"

export default function ChooseDate() {
  let history = useHistory()
  const navigationLocation = useLocation();
  const isDeepCleaning = navigationLocation.pathname === "/chk-deepCleaning";
  const dispatch = useDispatch()
  const oldMonth = useRef(null)
  const search = window.location.search
  const params = new URLSearchParams(search)
  const patientId = params.get("id")
  const locationId = params.get("location")
  
  let slotsFilterStartDate = undefined;
  if(params.get("slotsFilterStartDate")) {
    const date = moment(params.get("slotsFilterStartDate"));
    if(date.isValid()) {
      slotsFilterStartDate = date;
    }
  }

  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )

  const [isLoading, setIsLoading] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [timeSlot, setTimeSlot] = useState([])
  const [timeSlotLoder, setTimeSlotLoader] = useState(false)
  const [date, setDate] = useState(slotsFilterStartDate || moment())
  const [currentMonth, setCurrentMonth] = useState(slotsFilterStartDate || moment())
  const [monthTimeSlots, setMonthTimeSlots] = useState({})
  const [confirmationSection, setConfirmationSection] = useState(1)

  useEffect(() => {
    if (patientId) {
      dispatch(getSelectedPatient(patientId))
      dispatch(timeSlotAndLocationSuccess(null))
    }
  }, [patientId])

  parseConfig()

  useEffect(() => {
    if (date.month() !== currentMonth.month()) {
      setCurrentMonth(date)
    }
    setSelectedSlot(null)
  }, [date])

  useEffect(async () => {
    if (selectedPatient) {
      if (
        oldMonth.current &&
        oldMonth.current.year() === currentMonth.year() &&
        oldMonth.current.month() === currentMonth.month()
      ) {
        return
      }

      oldMonth.current = currentMonth

      setTimeSlotLoader(true)
      try {
        let getTimeSlote
        if (locationId) {
          getTimeSlote = await Parse.Cloud.run(
            "bookingSlotsRetrieveByDateRange",
            {
              locationId: locationId,
              reason: isDeepCleaning
                ? "Deep Cleaning"
                : selectedPatient?.hasCompletedAppointment
                  ? "Teeth Cleaning"
                  : "Other",
              startDateTime: moment(currentMonth)
                .startOf("month")
                .format("YYYY-MM-DD 00:00"),
              endDateTime: moment(currentMonth)
                .endOf("month")
                .format("YYYY-MM-DD 23:59:59"),
            }
          )
        } else {
          getTimeSlote = await Parse.Cloud.run(
            "bookingSlotsRetrieveByDateRange",
            {
              locationId: process.env.REACT_APP_ROTUNDA_LOCATION_ID,
              reason: isDeepCleaning
                ? "Deep Cleaning"
                : "Other",
              startDateTime: moment(currentMonth)
                .startOf("month")
                .format("YYYY-MM-DD 00:00"),
              endDateTime: moment(currentMonth)
                .endOf("month")
                .format("YYYY-MM-DD 23:59:59"),
            }
          )
        }
        setMonthTimeSlots(getTimeSlote)
        setTimeSlotLoader(false)
      } catch (error) {
        setTimeSlotLoader(false)
        toast.error(JSON.stringify(error.message))
      }
    }
  }, [currentMonth, selectedPatient])

  const disabledDays = useMemo(() => {
    const days = []
    for (const dateString of Object.keys(monthTimeSlots)) {
      if (!monthTimeSlots[dateString].length) {
        const date = moment(dateString)
        days.push({
          year: date.year(),
          month: date.month() + 1,
          day: date.date(),
        })
      }
    }
    return days
  }, [monthTimeSlots])

  useEffect(() => {
    if (date) {
      setTimeSlot(monthTimeSlots[date.format("YYYY-MM-DD")])
    }
  }, [date, monthTimeSlots])

  useEffect(() => {
    const findAvaliableSloteDate = () => {
      for (const key in monthTimeSlots) {
        if (monthTimeSlots.hasOwnProperty(key)) {
          if (monthTimeSlots[key].length > 0) {
            setDate(moment(key))
            break
          }
        }
      }
    }
    findAvaliableSloteDate()
  }, [monthTimeSlots])

  const goNext = async () => {
    if (!selectedSlot)
      return notify("Please select time slot to Continue", "error")
    try {
      setIsLoading(true);
      await Parse.Cloud.run("bookingAppointmentCreate", {
        patientId: selectedPatient?.objectId,
        locationId: locationId ? locationId : process.env.REACT_APP_ROTUNDA_LOCATION_ID,
        date: moment(selectedSlot?.date).format("YYYY-MM-DD"),
        start: selectedSlot?.start,
        generatedBy: "Web App",
        note: isDeepCleaning
          ? undefined
          : locationId != null
            ? "RECALL_PATIENT"
            : "REACTIVATION_PATIENT",
        isDeepCleaning: isDeepCleaning,
      })
      setConfirmationSection(2)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error(JSON.stringify(error.message))
    }
  }
  const selectedLocation = {
    objectId: locationId != null ? locationId : process.env.REACT_APP_ROTUNDA_LOCATION_ID,
  }

  return (
    <PageContainer
      step={4}
      leftContent={
        <>
          {confirmationSection === 1 ? (
            <>
              <div className="hidden md:block pb-2">
                <PageTitle title="Choose a time" className="mb-0" />
                <p className="text-sm font-semibold">Day and slot</p>
                <p className="mt-1 text-sm text-gray-500">
                  Select a preferable day and time below. Try selecting a
                  different day if one has no available slots.
                </p>
              </div>
              <div className="md:-mx-10 md:px-10 lg:-mx-16 lg:px-16 2xl:-mr-20 2xl:pr-20 2xl:-ml-10 2xl:pl-10 flex flex-col flex-grow md:overflow-x-hidden md:overflow-y-auto">
                <div className="mt-4">
                  <div className="mt-5 flex flex-col sm:flex-row md:flex-row pb-10 flex-wrap items-start gap-x-10 lg:gap-5 xl:gap-10">
                    <div className="flex-1 w-full sm:w-3/5 md:w-full lg:w-3/5 xl-3/5 sm:border-mobile-grey-800 relative">
                      <Calendar
                        value={{
                          year: date.year(),
                          month: date.month() + 1,
                          day: date.date(),
                        }}
                        onChange={(value) => {
                          const date = moment()
                          date.year(value.year)
                          date.month(value.month - 1)
                          date.date(value.day)
                          setDate(date)
                          dispatch(timeSlotAndLocationSuccess(null))
                        }}
                        onMonthChange={(value) => {
                          const date = moment()
                          date.year(value.year)
                          date.month(value.month - 1)
                          date.date(value.day)
                          setCurrentMonth(date)
                          dispatch(timeSlotAndLocationSuccess(null))
                        }}
                        onYearChange={(value) => {
                          const date = moment()
                          date.year(value.year)
                          date.month(value.month - 1)
                          date.date(value.day)
                          dispatch(timeSlotAndLocationSuccess(null))
                        }}
                        disabledDays={disabledDays}
                        calendarClassName="slot-calendar"
                        colorPrimary="#000000"
                        colorPrimaryLight="#000000"
                        shouldHighlightWeekends
                      />
                    </div>
                    <div className="mt-5 md:mt-5 w-full sm:w-2/5 md:w-full lg:w-2/5 xl-2/5">
                      <p className="mb-3 text-sm font-semibold">
                        Available slots
                      </p>
                      {timeSlotLoder && (
                        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-mobile-grey-50 bg-opacity-25">
                          <Loader />
                        </div>
                      )}

                      {!timeSlotLoder && timeSlot?.length ? (
                        <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
                          {timeSlot.map((slot) => {
                            return (
                              <SlotButton
                                value={slot}
                                key={slot.id}
                                onClick={() => {
                                  setSelectedSlot(slot)
                                  dispatch(
                                    timeSlotAndLocationSuccess({
                                      date: date.toDate(),
                                      timeSlot: slot,
                                    })
                                  )
                                }}
                                isSelected={slot.id === selectedSlot?.id}
                              />
                            )
                          })}
                        </div>
                      ) : (
                        <p className="mt-1.5 text-sm text-gray-500">
                          No slots are available on {date.format("LL")}. Try
                          looking for slots on a different date.
                        </p>
                      )}
                    </div>
                  </div>
                  <PublicPageAppointmentDetails
                    selectedPatient={selectedPatient}
                    locationId={locationId}
                  />
                </div>
              </div>
              <StickyContainer>
                <Button
                  onClick={goNext}
                  loading={isLoading}
                  title="Click here to book this appointment"
                />
              </StickyContainer>
            </>
          ) : (
            <>
              <PageTitle title="Appointment Booked">
                Your appointment has been booked.
              </PageTitle>
              <div className="">
                <PublicPageAppointmentDetails
                  selectedPatient={selectedPatient}
                  locationId={locationId}
                />
              </div>
            </>
          )}
        </>
      }
      rightContent={
        selectedLocation && (
          <Map
            locations={[selectedLocation]}
            initialZoom={15}
            hideOnMobile={true}
          />
        )
      }
    />
  )
}
