import Parse from "parse"
import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { notify } from "../../utils/helpers"
import BackButton from "../../components/BackButton"
import Button from "../../components/Button"
import PageContainer from "../../components/PageContainer"
import SlotButton from "../../components/SlotButton"
import PageTitle from "../../components/PageTitle"
import StickyContainer from "../../components/StickyContainer"
import {
  appointmentCreatedStatus,
  getLocation,
  rotundaSlotsFilter,
  timeSlotAndLocationSuccess,
} from "../../Store/Location/actions"
import LocationField from "./LocationField"
import Loader from "../../components/Loader"

import "../../components/calendar-datepicker/DatePicker.css"
import { Calendar } from "../../components/calendar-datepicker"
import Map from "../../components/Map"
import moment from "moment"

export default function ChooseDate() {
  let history = useHistory()
  const dispatch = useDispatch()

  const locations = useSelector((state) => state?.Location?.locations)
  const rotundaSlotsFillter = useSelector(
    (state) => state?.Location?.rotundaSlotsFilter
  )
  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )

  const [selectedSlot, setSelectedSlot] = useState(null)
  const [timeSlot, setTimeSlot] = useState([])
  const [timeSlotLoder, setTimeSlotLoader] = useState(false)
  const [date, setDate] = useState(moment())
  const [currentMonth, setCurrentMonth] = useState(moment())
  const [monthTimeSlots, setMonthTimeSlots] = useState({})

  useEffect(() => {
    dispatch(getLocation())
  }, [])

  useEffect(() => {
    if (date.month() !== currentMonth.month()) {
      setCurrentMonth(date)
    }
    setSelectedSlot(null)
  }, [date])

  const getLocationForCurrentAppointment = () => {
    if (
      selectedPatient?.hasCompletedAppointment ||
      locationHasWelcomeCenter()
    ) {
      return selectedPatient?.preferredLocation
    } else {
      return selectedPatient?.preferredLocation?.welcomeCenterLocation
    }
  }

  const goNext = async () => {
    if (!selectedSlot)
      return notify("Please select time slot to Continue", "error")
    dispatch(
      timeSlotAndLocationSuccess({
        location: getLocationForCurrentAppointment(),
        date: date.toDate(),
        timeSlot: selectedSlot,
      })
    )
    dispatch(appointmentCreatedStatus(false))
    history.push("/notes")
  }

  const locationHasWelcomeCenter = () => {
    if (selectedPatient?.hasCompletedAppointment) {
      return true
    } else {
      return (
        selectedPatient?.preferredLocation?.objectId ===
        selectedPatient?.preferredLocation?.welcomeCenterLocation?.objectId
      )
    }
  }

  const selectedLocation = locationHasWelcomeCenter()
    ? selectedPatient?.preferredLocation
    : selectedPatient?.preferredLocation?.welcomeCenterLocation

  const disabledDays = useMemo(() => {
    const days = []
    for (const dateString of Object.keys(monthTimeSlots)) {
      if (!monthTimeSlots[dateString].length) {
        const date = moment(dateString)
        days.push({
          year: date.year(),
          month: date.month() + 1,
          day: date.date(),
        })
      }
    }
    return days
  }, [monthTimeSlots, rotundaSlotsFillter])

  useEffect(() => {
    if (date) {
      setTimeSlot(monthTimeSlots[date.format("YYYY-MM-DD")])
    }
  }, [date, monthTimeSlots])

  useEffect(() => {
    const findAvaliableSloteDate = () => {
      for (const key in monthTimeSlots) {
        if (monthTimeSlots.hasOwnProperty(key)) {
          if (monthTimeSlots[key].length > 0) {
            setDate(moment(key))
            break
          }
        }
      }
    }
    findAvaliableSloteDate()
  }, [monthTimeSlots])

  const handleRotundaSlotsFillters = async () => {
    dispatch(rotundaSlotsFilter(true))
    setTimeSlotLoader(true)
    const getTimeSlote = await Parse.Cloud.run(
      "bookingSlotsRetrieveByDateRange",
      {
        locationId: process.env.REACT_APP_ROTUNDA_LOCATION_ID,
        reason: !selectedPatient?.hasCompletedAppointment
          ? "Other"
          : "Teeth Cleaning",
        startDateTime: moment(currentMonth.toDate())
          .startOf("month")
          .format("YYYY-MM-DD 00:00"),
        endDateTime: moment(currentMonth.toDate())
          .endOf("month")
          .format("YYYY-MM-DD 23:59:59"),
      }
    )
    setMonthTimeSlots(getTimeSlote)
    setTimeSlotLoader(false)
  }

  const rotundaLocationId = {
    objectId: process.env.REACT_APP_ROTUNDA_LOCATION_ID,
  }

  return (
    <PageContainer
      step={4}
      leftContent={
        <>
          <PageTitle title="Choose a slot" className="mb-0 !pb-0" />

          <div className="md:-mx-10 md:px-10 lg:-mx-16 lg:px-16 2xl:-mr-20 2xl:pr-20 2xl:-ml-10 2xl:pl-10 flex flex-col flex-grow md:overflow-x-hidden md:overflow-y-auto">
            <LocationField
              month={currentMonth.toDate()}
              setTimeSlot={setMonthTimeSlots}
              locations={locations}
              setTimeSlotLoader={setTimeSlotLoader}
              selectedPatient={selectedPatient}
            />
            <div className="mt-4">
              <p className="text-sm font-semibold">Day and slot</p>
              <p className="mt-1 text-sm text-gray-500">
                Select a preferable day and time below. Try selecting a
                different day if one has no available slots.
              </p>

              <div className="mt-5 flex flex-col sm:flex-row md:flex-row pb-10 flex-wrap items-start gap-x-10 lg:gap-5 xl:gap-10">
                <div className="flex-1 w-full sm:w-3/5 md:w-full lg:w-3/5 xl-3/5 sm:border-mobile-grey-800 relative">
                  <Calendar
                    value={{
                      year: date.year(),
                      month: date.month() + 1,
                      day: date.date(),
                    }}
                    onChange={(value) => {
                      const date = moment()
                      date.year(value.year)
                      date.month(value.month - 1)
                      date.date(value.day)
                      setDate(date)
                    }}
                    onMonthChange={(value) => {
                      const date = moment()
                      date.year(value.year)
                      date.month(value.month - 1)
                      date.date(value.day)
                      setCurrentMonth(date)
                    }}
                    onYearChange={(value) => {
                      const date = moment()
                      date.year(value.year)
                      date.month(value.month - 1)
                      date.date(value.day)
                      setCurrentMonth(date)
                    }}
                    disabledDays={disabledDays}
                    calendarClassName="slot-calendar"
                    colorPrimary="#000000"
                    colorPrimaryLight="#000000"
                    shouldHighlightWeekends
                  />
                </div>
                <div className="mt-5 md:mt-5 w-full sm:w-2/5 md:w-full lg:w-2/5 xl-2/5">
                  {rotundaSlotsFillter ? (
                    <p className="mb-3 text-sm cursor-pointer">
                      Looking for a spot on the weekend or it's an emergency?
                      Book at Rotunda today
                    </p>
                  ) : null}
                  <p className="mb-3 text-sm font-semibold">Available slots</p>
                  {timeSlotLoder && (
                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-mobile-grey-50 bg-opacity-25">
                      <Loader />
                    </div>
                  )}

                  {!timeSlotLoder && timeSlot?.length ? (
                    <div className="grid grid-cols-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-3">
                      {timeSlot.map((slot) => {
                        return (
                          <SlotButton
                            value={slot}
                            key={slot.id}
                            onClick={() => setSelectedSlot(slot)}
                            isSelected={slot.id === selectedSlot?.id}
                          />
                        )
                      })}
                    </div>
                  ) : (
                    <p className="mt-1.5 text-sm text-gray-500">
                      No slots are available on {date.format("LL")}. Try looking
                      for slots on a different date.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <StickyContainer>
            <BackButton />
            <Button onClick={goNext} />
          </StickyContainer>
        </>
      }
      rightContent={
        selectedLocation && (
          <Map
            locations={[
              rotundaSlotsFillter ? rotundaLocationId : selectedLocation,
            ]}
            initialZoom={15}
            hideOnMobile={true}
          />
        )
      }
    />
  )
}
