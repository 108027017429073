import { useState, useEffect, Fragment } from "react"
import Parse from "parse"
import * as Yup from "yup"
import { Form, FormikProvider, useFormik } from "formik"
import TextareaField from "../../components/TextareaField"
import Button from "../../components/Button"
import PageContainer from "../../components/PageContainer"
import PageTitle from "../../components/PageTitle"
import { toast } from "react-toastify"
import { recommendUsRatings } from "./reveiwData"
import { parseConfig } from "../../utils/ParseConfig";
import { Dialog, Transition } from "@headlessui/react"

export default function Review({ isOpen, closeModal, patientId }) {

  const search = window.location.search
  const params = new URLSearchParams(search)
  const appointmentId = params.get("id")
  const [isLoading, setIsLoading] = useState(false)
  const [rating, setRating] = useState(null)
  const [ratingError, setRatingError] = useState(false)

  const reviewSchema = Yup.object().shape({
    message: Yup.string()
      .required()
  })

  const formik = useFormik({
    initialValues: {
        message: "",
    },
    enableReinitialize: true,
    validationSchema: reviewSchema,
    onSubmit: async (values) => {
      if (rating === '' || rating === null) {
        setRatingError(true)
        return
      }
      setIsLoading(true)
      try {
        const feedback = await Parse.Cloud.run("bookingFeedbackCreateWeb", {
          appointmentId: appointmentId,
          patientId: patientId,
          source: "web",
          rating: Number(rating),
          message: values.message,
        })
        if (feedback) {
            setIsLoading(false)
            formik.resetForm()
            setRating(null)
            toast.success("Review submitted successfully!")
            closeModal()
        }
        
    } catch (error) {
        toast.error(JSON.stringify(error.message))
        setIsLoading(false)
      }
      setIsLoading(false)
    },
  })

  useEffect(() => {
    if (rating === null) {
      setRatingError(false)
      if (rating === '') {
        setRatingError(true)
      }
    } else {
      setRatingError(false)
    }

  }, [rating])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-10 text-gray-900"
                  > */}
                    <PageTitle title="Total Health Dental Care Patient Feedback" className="font-bold sm:text-4xl">
                      <span className="leading-6 font-normal">
                          Please give your valuable reviews and let us know what have to say about the THDC
                          services.
                      </span>
                    </PageTitle>
                  {/* </Dialog.Title> */}
                  
                  <FormikProvider value={formik}>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        
                        <div className="mb-7">
                            <div className="mb-3">
                                <label className="font-medium text-gray-700">1. How likely would you recommend THDC to a friend or colleague?</label>
                            </div>
                            <div className="flex items-center flex-wrap mt-5 mb-3 font-medium">
                                {
                                    recommendUsRatings.map((ratingObj, i) => {
                                        return <div key={i} className={`w-11 mb-2 h-11 px-3 py-3 shadow hover:shadow-lg 
                                            rounded-full cursor-pointer mr-2
                                            flex justify-center items-center text-xl 
                                            ${rating === ratingObj.value ? "bg-mobile-green-50 hover:bg-mobile-green-100 text-white" : 'bg-gray-200 hover:bg-gray-300'} `}
                                            onClick={() => setRating(ratingObj.value)}
                                        >
                                            {ratingObj.value}
                                        </div>
                                    })
                                }
                                <br />
                            </div>
                            { 
                              ratingError && <p className="text-red-600">Please select the Rating Value</p>
                            }
                        </div>
                        <br />
                        <div className="mb-7">
                            <div className="mb-5 ">
                                <label className="font-medium text-gray-700 ">2. What can we do better?</label>
                            </div>
                            <TextareaField
                              placeholder="Enter text here..."
                              name="message"
                              rows="5"
                              value={formik.values && formik.values.message}
                              onChange={formik.handleChange}
                              error={Boolean(formik.touched.message && formik.errors.message)}
                              helpertext={formik.errors.message}
                            />
                            {/* <ErrorMessage name="message" className="text-red-600" /> */}

                        </div>
                        
                        < br />
                        <div className="flex mt-auto w-full">
                          <button
                            type="button"
                            className="rounded-full mr-1 w-1/2 border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={closeModal}
                          >
                            Cancel
                          </button>
                          <Button 
                            loading={isLoading} 
                            onClick={formik.handleSubmit} 
                            title="Submit"
                            className="rounded-full ml-1 w-1/2 border border-transparent bg-mobile-green-50 px-4 py-2 text-sm font-medium text-white hover:shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          />
                        </div>
                    </Form>
                  </FormikProvider>

                  {/* <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
