import Parse from "parse"
import { all, fork, put, takeLatest } from "redux-saga/effects"
import {
  GET_MAIN_PATIENT,
  GET_SELECTED_PATIENT,
  GET_WELCOME_CENTER_SCREEN,
  LOGIN,
  REGISTER_USER,
  GET_SELECTED_PATIENT_CARD,
  GET_TIME_SLOTE_PUBLIC_PAGE_APPOINTMENT,
} from "../Auth/actionTypes"
import { sagaErrorHandler } from "../../utils/SagaErrorHandler"
import {
  getMainPatientSuccess,
  getSelectedPatientCardSuccess,
  getSelectedPatientSuccess,
  getTimeSlotPublicPageAppointmentSuccess,
  LoginSuccess,
  registerSuccess,
  VerifyNumber,
} from "./actions"

import axios from "axios"
axios.defaults.withCredentials = false

function* registerRequest({ payload }) {
  try {
    let data = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email.toLowerCase(),
      phone: payload.phone,
      reason: payload.note,
      generatedBy: "webBookingApp",
    }
    const username = yield Parse.Cloud.run("newBookingUser", data)
    yield put(registerSuccess(null))
    yield put(
      registerSuccess({
        username: username,
        phone: payload.phone,
        email: payload.email.toLowerCase(),
      })
    )
    payload.setIsLoading(false)
    payload.setVerifications(1)
  } catch (error) {
    payload.setIsLoading(false)
    yield sagaErrorHandler(error)
  }
}

function* loginRequest({ payload }) {
  try {
    yield Parse.User.logIn(payload.username, payload.password)
    yield put(LoginSuccess(Parse.User.current().toJSON()))

    const query = new Parse.Query("PatientV1")
    query.include("preferredLocation")
    const username = Parse.User.current().get("username")
    const patient = yield query.get(username)

    const patientData = patient.toJSON()
    yield put(getMainPatientSuccess(patientData))
    yield put(getSelectedPatientSuccess(patientData))
    yield put(VerifyNumber(true))

    // Log Attempt

    if (process.env.REACT_APP_SHEET_ATTEMPTS_PRODUCTION === "TRUE") {
      Parse.Cloud.run("addPatientLogToSpreadSheet", {
        patientId: patient.id,
        origin: "Web App",
        form: payload.formType == 1 ? "REGISTER" : "LOGIN",
      })
        .then(() => console.log("Log Attempt Success"))
        .catch(() => console.log("Log Attempt Error"))
    }

    if (!patient.get("dateOfBirth") || !patient.get("gender")) {
      payload.history.push("/details")
    } else {
      payload.history.push("/patients")
    }
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

function* getMainPatientRequest() {
  try {
    if (Parse.User.current()) {
      yield Parse.User.current()?.fetch()
      yield put(LoginSuccess(Parse.User.current().toJSON()))
      const query = new Parse.Query("PatientV1")
      query
        .include("preferredLocation")
        .include("preferredLocation.welcomeCenterLocation")
      const patientID = yield Parse.User.current()?.get("username")
      const patient = yield query.equalTo("objectId", patientID).first()
      yield put(getMainPatientSuccess(patient?.toJSON()))
    }
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

function* getSelectedPatientRequest({ payload }) {
  try {
    const query = new Parse.Query("PatientV1")
    query.include("preferredLocation")
    // query
    //   .include("preferredLocation")
    //   .include("preferredLocation.welcomeCenterLocation")
    const patient = yield query.get(payload)
    yield put(getSelectedPatientSuccess(patient.toJSON()))
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

function* getWelcomeCenterRequest({ payload }) {
  try {
    const query = new Parse.Query("PatientV1")
    query
      .include("preferredLocation")
      .include("preferredLocation.welcomeCenterLocation")
    const patient = yield query.get(payload.objectId)
    if (
      patient?.get("preferredLocation")?.id ===
      patient?.get("preferredLocation")?.get("welcomeCenterLocation")?.id
    ) {
      payload.history.push("chooseDate")
    } else {
      payload.history.push("/welcomeCenterLocations")
    }
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

function* getSelectedPatientCardRequest({ payload }) {
  const { mainPatient } = payload
  try {
    let response = yield Parse.Cloud.run("bookingPatientCardList", {
      patientId: mainPatient?.objectId,
    })
    yield put(
      getSelectedPatientCardSuccess(
        response?.map((cardList) => cardList.toJSON())
      )
    )
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

function* getTimeSlotPublicPageAppointmentRequest({ payload }) {
  try {
    payload.setAppointmentApi(false)
    const query = new Parse.Query("AppointmentV1")
    query
      .include("patient")
      .include("patient.preferredLocation")
      .include("patient.preferredLocation.welcomeCenterLocation")
    const appointment = yield query.get(payload?.appointmentId)
    yield put(getTimeSlotPublicPageAppointmentSuccess(appointment.toJSON()))
    payload.setAppointmentApi(true)
  } catch (error) {
    yield sagaErrorHandler(error)
  }
}

export function* watchRegister() {
  yield takeLatest(REGISTER_USER, registerRequest)
}
export function* watchLogin() {
  yield takeLatest(LOGIN, loginRequest)
}
export function* watchGetMainPatient() {
  yield takeLatest(GET_MAIN_PATIENT, getMainPatientRequest)
}
export function* watchGetSelectedPatient() {
  yield takeLatest(GET_SELECTED_PATIENT, getSelectedPatientRequest)
}
export function* watchGetWelcomeCenterRequest() {
  yield takeLatest(GET_WELCOME_CENTER_SCREEN, getWelcomeCenterRequest)
}
export function* watchGetSelectedPatientCard() {
  yield takeLatest(GET_SELECTED_PATIENT_CARD, getSelectedPatientCardRequest)
}
export function* watchGetTimeSlotPublicPageAppointment() {
  yield takeLatest(
    GET_TIME_SLOTE_PUBLIC_PAGE_APPOINTMENT,
    getTimeSlotPublicPageAppointmentRequest
  )
}

export default function* authSaga() {
  yield all([
    fork(watchRegister),
    fork(watchLogin),
    fork(watchGetMainPatient),
    fork(watchGetSelectedPatient),
    fork(watchGetWelcomeCenterRequest),
    fork(watchGetSelectedPatientCard),
    fork(watchGetTimeSlotPublicPageAppointment),
  ])
}
