import { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Form, FormikProvider, Field } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import DatePicker from "react-date-picker"
import "react-date-picker/dist/DatePicker.css"
import Button from "../../components/Button"
import InputField from "../../components/InputField"
import PageTitle from "../../components/PageTitle"
import RadioInput from "../../components/RadioInput"
import StickyContainer from "../../components/StickyContainer"
import PageContainer from "../../components/PageContainer"
import { AddFamilyMember } from "../../Store/CreateFamilyMember/actions"
import { toast } from "react-toastify"
import { getMainPatient } from "../../Store/Auth/actions"
import { formatDOB } from "../../utils/helpers"
import moment from "moment"

export default function AddNewFamilyMember() {

  const customStyle = "w-full py-2 px-3 placeholder-mobile-grey-400 bg-gray-100 focus:outline-none lg:text-3xl rounded-md border-0"
  const errorStyle = "w-full py-2 px-3 placeholder-mobile-grey-400 bg-gray-100 focus:outline-none lg:text-3xl rounded-md border-solid border-2 border-red-400 border-opacity-1"

  const dispatchAction = useDispatch()
  let history = useHistory()
  const selectedPatient = useSelector(
    (state) => state?.AuthRed?.selectedPatient
  )
  const user = useSelector((state) => state?.AuthRed?.user)

  const [dateOfBirth, setDateOfBirth] = useState()
  const [gender, setGender] = useState("M")
  const [yesterdayDay, setYesterdayDay] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatchAction(getMainPatient())
  }, [])

  const addFamilyMemberSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Please enter first name")
      .matches(/\S/, "Invalid email"),
    lastName: Yup.string()
      .required("Please enter last name")
      .matches(/\S/, "Invalid email"),
    dateOfBirth: Yup.date()
      .nullable()
      .required("Please select Date of Birth")
  })
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
    enableReinitialize: true,
    validationSchema: addFamilyMemberSchema,
    onSubmit: async (values) => {
      if (isDobValid(dateOfBirth)) {
        setIsLoading(true)
        dispatchAction(
          AddFamilyMember({
            firstName: values.firstName,
            lastName: values.lastName,
            dateOfBirth: dateOfBirth,
            gender: gender,
            user: user,
            history: history,
            setIsLoading: setIsLoading,
          })
        )
      }
    },
  })

  function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime())
    previous.setDate(date.getDate() - 1)
    setYesterdayDay(previous)
    return previous
  }
 
  useEffect(() => {
    getPreviousDay()
  }, [])

  function isDobValid(dob) {
    if (dob < new Date("1900-01-01")) {
      toast.error(`INVALID DATE OF BIRTH ${moment(dob).format("YYYY-MM-DD")}`)
      return false
    } else {
      return true
    }
  }

  return (
    <PageContainer
      step={2}
      leftContent={
        <>
          <PageTitle title="Add family member">
            We need a few details from you in order to book an appointment.
          </PageTitle>

          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <div className="flex justify-between mb-5">
                <div className="w-full mr-3">
                  <p className="mb-1">First name</p>
                  <InputField
                    placeholder="First name"
                    name="firstName"
                    value={formik.values && formik.values.firstName}
                    onChange={formik.handleChange}
                    className="mr-3"
                    error={Boolean(
                      formik.touched.firstName && formik.errors.firstName
                    )}
                    helpertext={formik.errors.firstName}
                  />
                </div>
                <div className="w-full mr-3">
                  <p className="mb-1">Last name</p>
                  <InputField
                    placeholder="Last name"
                    name="lastName"
                    value={formik.values && formik.values.lastName}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.lastName && formik.errors.lastName
                    )}
                    helpertext={formik.errors.lastName}
                  />
                </div>
              </div>
              <div className="mb-5">
                <p className="mb-1">Date of birth</p>
                <Field name="dateOfBirth" >
                    {({ field, meta , form: { setFieldValue, errors , touched }}) => {
                      return (
                        <>
                          <DatePicker
                            {...field}
                              maxDetail="month"
                              locale="en-US"
                              dayPlaceholder="dd"
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              yearAriaLabel="Year"
                              format="MM/dd/yyyy"
                              value={dateOfBirth}
                              minDate={new Date("1900-01-01")}
                              maxDate={yesterdayDay}
                              onChange={(date) => (
                                setDateOfBirth(date),
                                setFieldValue(field.name, date)
                              )}
                              className={meta.touched && meta.error ? errorStyle : customStyle}
                              selected={field.value || null}
                          />
                            {meta.touched && meta.error &&  <span className="text-sm mt-1 text-red-400">{meta.error}</span> }
                        </>
                      )
                    }
                  }
                </Field>
              </div>
              <div className="text-mobile-grey-600 mb-10">
                <h6 className="font-bold text-sm md:text-lg">Gender</h6>
                <div className="flex mt-3">
                  <RadioInput
                    name="gender"
                    value="M"
                    onChange={setGender}
                    option={{ title: "Male", text: "" }}
                    selectedOption={gender}
                  />
                  <RadioInput
                    name="gender"
                    value="F"
                    onChange={setGender}
                    option={{ title: "Female", text: "" }}
                    selectedOption={gender}
                  />
                  <RadioInput
                    name="gender"
                    value="O"
                    onChange={setGender}
                    option={{ title: "Other", text: "" }}
                    selectedOption={gender}
                  />
                </div>
              </div>
              <StickyContainer>
                <Button loading={isLoading} onClick={formik.handleSubmit} />
              </StickyContainer>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}