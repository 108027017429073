export const locationGeo = {
  "9000000000623" : [-122.2742583, 37.8008749],
  "9000000000634" : [-122.2603305, 37.8541701],
  "9000000000635" : [-122.2483022, 37.8308693],
  "9000000000636" : [-122.259954, 37.8555291],
  "9000000000644" : [-122.261372, 37.8637309],
  "9000000000645" : [-122.2692812, 37.8043626],
  "9000000000646" : [-122.3027542, 37.8377053],
  "9000000000660" : [-122.2093167, 37.8261488],
  "9000000000661" : [-122.262081, 37.8209967],
  "9000000000700" : [-122.2907019, 37.8905488],
  "9000000000743" : [-122.2456626, 37.8186966],
  "9000000000750" : [-122.131373, 37.9935958],
  "9000000000778" : [-121.9719197, 37.7685119],
  "9000000000800" : [-122.2751408, 37.7833574],
  "9000000000810" : [-122.2751408, 37.7833574],
  "9000000000821" : [-122.2606409, 37.829127],
  "9000000000824" : [-122.2606409, 37.829127],
  "9000000000830" : [-122.4178026, 37.789371],
  "9000000000840" : [-122.2646307, 37.8174696],
  "9000000000842" : [-122.271726, 37.8057206],
  "9000000000843" : [-122.2646307, 37.8174696],
  "9000000000875" : [-122.2872229, 37.527001],
}
