import React from "react"

const TextareaField = ({
  innerRef = null,
  className = "",
  inputClass = "",
  icon = null,
  name,
  error,
  ...props
}) => {
  return (
    <div className={`flex flex-col relative ${className}`}>
      <div className="flex flex-col justify-center">
        {icon}
        <textarea
          maxLength={90}
          className={`w-full py-2 px-3 placeholder-mobile-grey-400 border-0 focus:outline-none focus:ring-2 lg:text-xl bg-gray-100 rounded ${
            error
              ? "ring-2 ring-red-400 focus:ring-red-400"
              : "focus:ring-mobile-green-50"
          } ${inputClass}`}
          name={name}
          {...props}
        ></textarea>
      </div>

      {error && <span className="text-sm mt-1 text-red-400">{error}</span>}
    </div>
  )
}

export default TextareaField
