import moment from "moment"
import React from "react"

const InsuranceDetails = ({ insuranceInfo }) => {
  return (
    <>
      <p className="text-sm md:text-lg mt-1 md:mt-3 text-mobile-grey-600 mb-4">
        Insurance Details
      </p>
      <table className="table-auto mb-5">
        <tbody>
          {insuranceInfo?.insuranceProvider && <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Insurance Provider:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.insuranceProvider}
            </td>
          </tr>}
          {insuranceInfo?.state && <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>State:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.state}
            </td>
          </tr>}
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Date of birth:</b>
            </td>
            <td className="p-2">
                {moment(insuranceInfo?.dateOfBirth?.iso).format("MM-DD-YYYY")}
            </td>
          </tr>
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Subscriber Name:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.subscriberName}
            </td>
          </tr>
          <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Subscriber SSN:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.subscriberSsn}
            </td>
          </tr>
          {insuranceInfo?.subscriberId && <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Subscriber ID:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.subscriberId}
            </td>
          </tr>}
          {insuranceInfo?.membershipId && <tr>
            <td className="p-2 text-sm md:text-md mt-1 md:mt-3 text-mobile-grey-600">
              <b>Membership ID:</b>
            </td>
            <td className="p-2">
                {insuranceInfo?.membershipId}
            </td>
          </tr>}
        </tbody>
      </table>
    </>
  )
}

export default InsuranceDetails
