import Parse from "parse"
import { useEffect, useRef } from "react"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { removeTotalHeathCareName } from "../../utils/helpers"
import { toast } from "react-toastify"
import {
  getMainPatient,
  getSelectedPatientSuccess,
} from "../../Store/Auth/actions"

export default function LocationField(props) {
  const { setTimeSlot, month, setTimeSlotLoader, locations, selectedPatient } =
    props

  const oldMonth = useRef(null)

  const filteredLocations = locations?.filter(
    (location) => !location.isWelcomeCenter && !location.isRemote
  )
  const rotundaSlotsFillter = useSelector(
    (state) => state?.Location?.rotundaSlotsFilter
  )
  const dispatch = useDispatch()

  const handleChange = async (location) => {
    setTimeSlotLoader(true)
    try {
      await Parse.Cloud.run("updatePatientPreferredLocation", {
        locationId: location?.value,
        patientId: selectedPatient?.objectId,
      })
      dispatch(getMainPatient())
      const query = new Parse.Query("PatientV1")
      query
        .include("preferredLocation")
        .include("preferredLocation.welcomeCenterLocation")
      const patient = await query.get(selectedPatient?.objectId)
      if (patient) {
        const getTimeSlote = await Parse.Cloud.run(
          "bookingSlotsRetrieveByDateRange",
          {
            locationId: patient?.get("preferredLocation")?.id,
            reason: !patient?.get("hasCompletedAppointment")
              ? "Other"
              : "Teeth Cleaning",
            startDateTime: moment(month)
              .startOf("month")
              .format("YYYY-MM-DD 00:00"),
            endDateTime: moment(month)
              .endOf("month")
              .format("YYYY-MM-DD 23:59:59"),
          }
        )
        setTimeSlot(getTimeSlote)
        setTimeSlotLoader(false)
        dispatch(getSelectedPatientSuccess(patient.toJSON()))
        dispatch(getMainPatient())
      }
    } catch (error) {
      setTimeSlotLoader(true)
      toast.error(JSON.stringify(error.message))
    }
  }

  // const locationHasWelcomeCenter = () => {
  //   if (selectedPatient?.hasCompletedAppointment) {
  //     return true
  //   } else {
  //     return (
  //       selectedPatient?.preferredLocation?.objectId ===
  //       selectedPatient?.preferredLocation?.welcomeCenterLocation?.objectId
  //     )
  //   }
  // }

  // const getLocationId = () => {
  //   if (locationHasWelcomeCenter()) {
  //     return selectedPatient?.preferredLocation?.objectId
  //   } else {
  //     return selectedPatient?.preferredLocation?.welcomeCenterLocation?.objectId
  //   }
  // }

  useEffect(() => {
    dispatch(getMainPatient())
  }, [])

  useEffect(async () => {
    if (
      oldMonth.current &&
      oldMonth.current.getFullYear() === month.getFullYear() &&
      oldMonth.current.getMonth() === month.getMonth()
    ) {
      return
    }
    oldMonth.current = month

    if (!!selectedPatient) {
      // const locationId = getLocationId()
      if (selectedPatient?.preferredLocation?.objectId) {
        setTimeSlotLoader(true)
        const getTimeSlote = await Parse.Cloud.run(
          "bookingSlotsRetrieveByDateRange",
          {
            locationId: rotundaSlotsFillter
              ? process.env.REACT_APP_ROTUNDA_LOCATION_ID
              : selectedPatient?.preferredLocation?.objectId,
            reason: !selectedPatient?.hasCompletedAppointment
              ? "Other"
              : "Teeth Cleaning",
            startDateTime: moment(month)
              .startOf("month")
              .format("YYYY-MM-DD 00:00"),
            endDateTime: moment(month)
              .endOf("month")
              .format("YYYY-MM-DD 23:59:59"),
          }
        )
        setTimeSlot(getTimeSlote)
        setTimeSlotLoader(false)
      }
    }
  }, [month, selectedPatient, rotundaSlotsFillter]) //TIME_SLOT_AVAILABILITY

  return (
    <>
      {selectedPatient.hasCompletedAppointment ? (
        <div className="mb-4 sm:mb-6 md:block">
          <p className="mb-2 text-sm font-semibold">Location</p>
          <Select
            name="location"
            placeholder="Preffered location"
            className="react-select-container"
            classNamePrefix="react-select"
            value={{
              value: selectedPatient?.preferredLocation?.objectId,
              label: selectedPatient?.preferredLocation?.name,
            }}
            options={filteredLocations?.map((loc) => {
              return {
                value: loc?.objectId,
                label: removeTotalHeathCareName(loc?.name),
              }
            })}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </>
  )
}
